interface regexConditions {
    passwordLength?: number,
    isNumRequired?: boolean,
    isSpecialCharRequired?: boolean,
    isUpperCaseCharRequired?: boolean
}

export const constructRegex = ({ passwordLength = 8, isNumRequired = false, isSpecialCharRequired = false, isUpperCaseCharRequired = false }: regexConditions): RegExp => {
    let regexPattern = `(?=.{${passwordLength},})`;

    // Add conditions for numbers, special characters, and uppercase letters
    if (isNumRequired) {
        regexPattern += "(?=.*[0-9])";
    }
    if (isSpecialCharRequired) {
        // Note: You might need to escape certain special characters
        regexPattern += "(?=.*[!@#$%^&*])";
    }
    if (isUpperCaseCharRequired) {
        regexPattern += "(?=.*[A-Z])";
    }

    // Complete the regex pattern for the entire password
    regexPattern += ".*";

    // Create and return the RegExp object
    const regex = new RegExp(`^${regexPattern}$`);
    return regex;
} 