import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import msIcon from "../assets/images/ms-icon.svg";
import defaultLogo from "../assets/images/logo.png";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import "./Login.scss";
import CustomInput from "../components/CustomInput";
import { validateEmail, validatePassword } from "../utils/validator";
import { Error } from "@progress/kendo-react-labels";
import accountService from "../services/account.service";
import { UserLoginRequest } from "../types/UserLoginRequest";
import { useEffect, useState } from "react";
import { AxiosError } from "axios";
import { Loader } from "@progress/kendo-react-indicators";
import { useMsal } from "@azure/msal-react";
import { AccountInfo, SilentRequest } from "@azure/msal-browser";
import LoadingOverlay from "../components/LoadingOverlay";
import { AppTokenRequest } from "../types/AppTokenRequest";
import { Dictionary } from "../types/Dictionary";
import useLocale from "../hooks/useLocale";
import Swal from "sweetalert2";
import useBranding from "../hooks/useBranding";

const emailValidator = (value: string) => {
  if (!value) {
    return <Error>{"Email address cannot be empty."}</Error>;
  }
  if (!validateEmail(value)) {
    return <Error>{"Invalid email address."}</Error>;
  }
};

const passwordValidator = (value: string) => {
  if (!value) {
    return <Error>{"Password cannot be empty."}</Error>;
  }
};

const Login: React.FC = () => {
  const brandingCtx = useBranding();
  const localeCtx = useLocale();
  const { instance: microsoftInstance } = useMsal();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [error, setError] = useState<string>();
  const [userLoggingIn, setUserLoggingIn] = useState<boolean>(false);
  const [redirecting, setRedirecting] = useState<boolean>(false);
  const [accountDisabled, setAccountDisabled] = useState<boolean>(false);
  const [translationsLoading, setTranslationsLoading] =
    useState<boolean>(false);
  const [translations, setTranslations] = useState<
    Dictionary<string> | undefined
  >(localeCtx?.selectedLocale?.current.componentTranslations["login"]);

  const clientId = searchParams.get("clientId");
  const redirectUri = searchParams.get("redirectUri");

  //#region useEffects
  useEffect(() => {
    const fetchTranslations = async () => {
      try {
        setTranslationsLoading(true);
        const resp = await localeCtx?.setComponentTranslations("login");
        setTranslations(resp);
      } catch (err) {
        setTranslations(
          localeCtx?.selectedLocale?.previous.componentTranslations["login"]
        );
        localeCtx?.setPreviousAppLocale("login");
        if (localeCtx?.localeSwitchFailed) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Couldn't Switch Language",
          });
        }
      } finally {
        setTimeout(() => {
          setTranslationsLoading(false);
        }, 100);
      }
    };

    if (!localeCtx?.selectedLocale?.current.componentTranslations["login"]) {
      fetchTranslations();
    }
  }, [localeCtx?.selectedLocale]);

  useEffect(() => {
    if (!clientId) {
      // redirect to unauthorized
      navigate(`/unauthorized`, { replace: true });
    } else {
      getRegisteredAppToken(clientId);
    }
  }, []);
  //#endregion useEffects

  //#region Locale Translation Methods
  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };
  //#endregion Locale Translation Methods

  //#region Handlers
  const getRegisteredAppToken = async (clientId: string) => {
    try {
      setRedirecting(true);
      const reqPayload: AppTokenRequest = {
        redirectUri: redirectUri ?? "",
      };
      const appTokenResponse = await accountService.getAppToken(
        reqPayload,
        clientId
      );
      const redirectUrl = new URL(appTokenResponse.redirectUri);
      if (redirectUrl.search === "") {
        appTokenResponse.redirectUri = `${appTokenResponse.redirectUri}?appToken=${appTokenResponse.token}`;
      } else {
        appTokenResponse.redirectUri = `${appTokenResponse.redirectUri}&appToken=${appTokenResponse.token}`;
      }

      appTokenResponse.redirectUri = `${appTokenResponse.redirectUri
        }&username=${encodeURIComponent(appTokenResponse.username)}&language=${encodeURIComponent(localeCtx?.selectedLocale?.current.locale.code ?? "en-UK")}`;
      window.location.replace(appTokenResponse.redirectUri);
    } catch (err) {
      if (err instanceof AxiosError) {
        const errCode = err.response?.data?.code;
        if (errCode !== "UNAUTHORIZED") {
          const errMessage = err.response?.data?.message;
          setError(errMessage);
        }
      }
    } finally {
      setRedirecting(false);
    }
  };

  const getAPIAccessToken = async (reqPayload: UserLoginRequest) => {
    try {
      if (clientId) {
        const token = await accountService.login(reqPayload, clientId);
        setAccountDisabled(false);

        getRegisteredAppToken(clientId);
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        const errCode = err.response?.data?.code;
        if (errCode === "ACCOUNT_LOCKED") {
          setAccountDisabled(true);
        }

        const errMessage = err.response?.data?.message;
        setError(errMessage);
      }
    }
  };

  const userLoginHandler = async (values: { [name: string]: any }) => {
    const reqPayload: UserLoginRequest = {
      username: values.email,
      password: values.password,
    };
    setUserLoggingIn(true);
    await getAPIAccessToken(reqPayload);
    setUserLoggingIn(false);
  };

  const getMicrosoftGraphToken = async (account: AccountInfo) => {
    const tokenRequest: SilentRequest = {
      scopes: ["User.Read", "openid", "profile"],
      account: account,
    };

    let token: string | undefined;
    try {
      const { accessToken } = await microsoftInstance.acquireTokenSilent(
        tokenRequest
      );
      token = accessToken;
    } catch (e) {
      const { accessToken } = await microsoftInstance.acquireTokenPopup(
        tokenRequest
      );
      token = accessToken;
    }

    return token;
  };

  const userThirdPartyLoginHandler = async (
    thirdPartyProvider: "Microsoft"
  ) => {
    try {
      if (clientId) {
        switch (thirdPartyProvider) {
          case "Microsoft": {
            const { account } = await microsoftInstance.loginPopup();
            if (account) {
              const token = await getMicrosoftGraphToken(account);
              const reqPayload: UserLoginRequest = {
                username: account.username,
                accessToken: token,
                thirdPartyProvider: "Microsoft",
              };
              await getAPIAccessToken(reqPayload);
            }
            break;
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
  };
  //#endregion Handlers

  return (
    <div className="loginFull float-left w-100 h-100">
      <div className="loginBg h-100 p-l-15 p-r-15">
        <div className="row m-b-20 h-100">
          <div className="col-md-12 h-100">
            {redirecting && (
              <LoadingOverlay
                customStyle={{ position: "fixed", marginTop: "55px" }}
                themeColor={"light"}
                size={"medium"}
              />
            )}
            <div className="formGroup h-100">
              <div
                className="formInrG cardEffect"
                style={{ position: "relative" }}
              >
                {brandingCtx?.branding?.logos.find(
                  (l) => l.name === "HeaderPrimaryLogo"
                )?.logoImageUrl && (
                  <div className="hdrTrk-logo text-center p-t-5 p-b-15">
                    {/* <span className="fs-22 font-weight">LOGO_PLACEHOLDER</span> */}
                    <img
                      src={
                        brandingCtx?.branding?.logos.find(
                          (l) => l.name === "HeaderPrimaryLogo"
                        )?.logoImageUrl
                      }
                      alt="Logo"
                    />
                  </div>
                )}
                <Form
                  initialValues={{
                    email: "",
                    password: undefined,
                  }}
                  onSubmit={userLoginHandler}
                  render={(formRenderProps) => (
                    <FormElement style={{ maxWidth: "100%" }}>
                      <fieldset className={"k-form-fieldset"}>
                        <legend
                          className={"k-form-legend fs-18 font-weight-semi"}
                          style={{ textTransform: "none" }}
                        >
                          {translationsLoading
                            ? "LoginFormTitle"
                            : fetchLabelKeyTranslation(
                                "LoginFormTitle",
                                "Sign In To Your Account"
                              )}
                        </legend>
                        {error && <Error>{error}</Error>}
                        <div className="m-b-15">
                          <Field
                            placeholder={
                              translationsLoading
                                ? "EmailFieldPlaceholder"
                                : fetchLabelKeyTranslation(
                                    "EmailFieldPlaceholder",
                                    "Email"
                                  )
                            }
                            name="email"
                            type="email"
                            value={formRenderProps.valueGetter("email")}
                            component={CustomInput}
                            onChange={() => setError("")}
                          />
                          {formRenderProps.modified &&
                            emailValidator(
                              formRenderProps.valueGetter("email")
                            )}
                        </div>
                        <div className="m-b-1">
                          <Field
                            placeholder={
                              translationsLoading
                                ? "PasswordFieldPlaceholder"
                                : fetchLabelKeyTranslation(
                                    "PasswordFieldPlaceholder",
                                    "Password"
                                  )
                            }
                            name="password"
                            type="password"
                            value={formRenderProps.valueGetter("password")}
                            component={CustomInput}
                            onChange={() => setError("")}
                          />
                          {/* NOTE: This code should be uncommented if we need strict 
                          password validations in the login page */}
                          {formRenderProps.modified &&
                            passwordValidator(
                              formRenderProps.valueGetter("password")
                            )}
                        </div>
                      </fieldset>
                      <div className="k-form-buttons d-flex justify-content-center m-t-15">
                        <Button
                          type={"submit"}
                          style={{ width: "100%", textTransform: "uppercase" }}
                          className={`btn bg-primary text-white fs-16 p-t-7 p-b-7 ${
                            accountDisabled && "disabledBtn"
                          }`}
                          disabled={
                            accountDisabled || 
                            !formRenderProps.valueGetter("password") ||
                            !formRenderProps.valueGetter("email")
                          }
                        >
                          <i className="bi bi-lock fs-14 p-r-5"></i>
                          {userLoggingIn ? (
                            <Loader
                              size="small"
                              type="infinite-spinner"
                              themeColor="light"
                            />
                          ) : translationsLoading ? (
                            "Login"
                          ) : (
                            fetchLabelKeyTranslation("LoginButtonText", "Login")
                          )}
                        </Button>
                      </div>
                      <div className="row m-t-10">
                        <div className="col-md-12">
                          <div className="formLabel p-0 d-flex justify-content-space-between">
                            <Link
                              to={`/forgot-password?clientId=${clientId}${
                                redirectUri !== null
                                  ? `&redirectUri=` + redirectUri
                                  : ""
                              }`}
                              className="forgotPasswordLbl btn-link"
                            >
                              {translationsLoading
                                ? "Forgot Password ?"
                                : fetchLabelKeyTranslation(
                                    "ForgotPasswordText",
                                    "Forgot Password ?"
                                  )}
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="or-divider p-t-10 p-b-10">
                        <span>
                          {translationsLoading
                            ? "OR SIGN IN WITH"
                            : fetchLabelKeyTranslation(
                                "SignInByThirdPartyText",
                                "OR SIGN IN WITH"
                              )}
                        </span>
                      </div>
                      <div className="k-form-buttons d-flex justify-content-center m-t-10">
                        <Button
                          type={"button"}
                          style={{ width: "100%", textTransform: "uppercase" }}
                          className={`btn bg-primary text-white fs-16 p-t-7 p-b-7 imgBtn`}
                          onClick={() =>
                            userThirdPartyLoginHandler("Microsoft")
                          }
                        >
                          <img
                            className="p-r-5"
                            src={msIcon}
                            height="24spx"
                            width="24px"
                          />
                          {translationsLoading
                            ? "Microsoft"
                            : fetchLabelKeyTranslation(
                                "TextMicrosoft",
                                "Microsoft"
                              )}
                        </Button>
                      </div>
                    </FormElement>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
