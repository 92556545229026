import { AxiosError } from "axios";
import { AppTokenRequest } from "../types/AppTokenRequest";
import { AppTokenResponse } from "../types/AppTokenResponse";
import { ChangePasswordRequest } from "../types/ChangePasswordRequest";
import { ForgotPasswordRequest } from "../types/ForgotPasswordRequest";
import { ResetPasswordRequest } from "../types/ResetPasswordRequest";
import { Response } from "../types/Response";
import { UserLoginRequest } from "../types/UserLoginRequest";
import { customAxios } from "../utils/customAxios";
import { ValidateResetToken } from "../types/ValidateResetToken";
import { PasswordPolicy } from "../types/PasswordPolicy";

const accountService = {
  login: async (
    userInfo: UserLoginRequest,
    clientId: string
  ): Promise<string> => {
    customAxios.defaults.headers.post["x-tollringauth-clientid"] = clientId;
    const response = await customAxios.post("/account/login", userInfo);
    const tokenResponse: Response<{ token: string }> = response.data;
    if (tokenResponse.code === "SUCCESS" && tokenResponse.data) {
      return tokenResponse.data?.token;
    } else {
      throw Error(tokenResponse.error);
    }
  },
  getAppToken: async (
    reqBody: AppTokenRequest,
    clientId: string
  ): Promise<AppTokenResponse> => {
    customAxios.defaults.headers.post["x-tollringauth-clientid"] = clientId;
    const response = await customAxios.post("/account/token", reqBody);
    const appTokenResponse: Response<AppTokenResponse> = response.data;
    if (appTokenResponse.code === "SUCCESS" && appTokenResponse.data) {
      return appTokenResponse.data;
    } else {
      throw Error(appTokenResponse.error);
    }
  },
  forgotPassword: async (
    reqBody: ForgotPasswordRequest,
    clientId: string
  ): Promise<string> => {
    customAxios.defaults.headers.post["x-tollringauth-clientid"] = clientId;
    const response = await customAxios.post(
      "/account/forgot-password",
      reqBody
    );
    const resp: Response<string> = response.data;
    if (resp.code === "SUCCESS" && resp.data) {
      return resp.data;
    } else {
      throw Error(resp.error);
    }
  },
  resetPassword: async (
    reqBody: ResetPasswordRequest,
    clientId: string
  ): Promise<string> => {
    customAxios.defaults.headers.post["x-tollringauth-clientid"] = clientId;
    const response = await customAxios.post("/account/reset-password", reqBody);
    const resp: Response<string> = response.data;
    if (resp.code === "SUCCESS" && resp.data) {
      return resp.data;
    } else {
      throw Error(resp.error);
    }
  },
  changePassword: async (
    reqBody: ChangePasswordRequest,
    clientId: string
  ): Promise<string> => {
    customAxios.defaults.headers.post["x-tollringauth-clientid"] = clientId;
    const response = await customAxios.post(
      "/account/change-password",
      reqBody
    );
    const resp: Response<string> = response.data;
    if (resp.code === "SUCCESS" && resp.data) {
      return resp.data;
    } else {
      throw Error(resp.error);
    }
  },
  logout: async (clientId: string): Promise<boolean> => {
    console.log("Logging out API called");
    customAxios.defaults.headers.post["x-tollringauth-clientid"] = clientId;
    const response = await customAxios.post("/account/logout");
    const resp: Response<boolean> = response.data;
    return resp.data ?? false;
  },
  validateresettoken: async (validateToken: ValidateResetToken, clientId: string):
    Promise<boolean> => {
    customAxios.defaults.headers.post["x-tollringauth-clientid"] = clientId;
    const response = await customAxios.post("/account/valresettoken", validateToken);
    const resp: Response<boolean> = response.data;
    return resp.data ?? false;
  },
  passwordPolicy: async (email: string | null): Promise<PasswordPolicy> => {
    const requestQuery = email == null ? '' : `?userName=${email}`;
    const response = await customAxios.get(`/account/passwordpolicy` + requestQuery);
    const resp: Response<PasswordPolicy> = response.data;
    if (resp.code == "SUCCESS" && resp.data) {
      return resp.data;
    }
    else {
      throw Error(resp.error);
    }
  }
};

export default accountService;
