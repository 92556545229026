import { Button } from "@progress/kendo-react-buttons";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import LoadingOverlay from "../components/LoadingOverlay";
import defaultLogo from "../assets/images/logo.png";
import CustomInput from "../components/CustomInput";
import { validateEmail, validateOnPasswordPolicy, validatePassword } from "../utils/validator";
import { Error } from "@progress/kendo-react-labels";
import { useEffect, useState } from "react";
import { ResetPasswordRequest } from "../types/ResetPasswordRequest";
import accountService from "../services/account.service";
import { AxiosError } from "axios";
import { Loader } from "@progress/kendo-react-indicators";
import Swal from "sweetalert2";
import useLocale from "../hooks/useLocale";
import { Dictionary } from "../types/Dictionary";
import useBranding from "../hooks/useBranding";
import { ValidateResetToken } from "../types/ValidateResetToken";
import { PasswordPolicy } from "../types/PasswordPolicy";

const ResetPassword: React.FC = () => {
  const localeCtx = useLocale();
  const brandingCtx = useBranding();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [redirecting, setRedirecting] = useState<boolean>(false);
  const [translationsLoading, setTranslationsLoading] =
    useState<boolean>(false);
  const [translations, setTranslations] = useState<
    Dictionary<string> | undefined
  >(localeCtx?.selectedLocale?.current.componentTranslations["resetpassword"]);

  const [passwordPolicy, setPasswordPolicy] = useState<PasswordPolicy>();

  const user = searchParams.get("user");
  const token = searchParams.get("token");
  const clientId = searchParams.get("clientId");

  useEffect(() => {
    const fetchTranslations = async () => {
      try {
        setTranslationsLoading(true);
        const resp = await localeCtx?.setComponentTranslations("resetpassword");
        setTranslations(resp);
      } catch (err) {
        setTranslations(
          localeCtx?.selectedLocale?.previous.componentTranslations["resetpassword"]
        );
        localeCtx?.setPreviousAppLocale("resetpassword");
        if (localeCtx?.localeSwitchFailed) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Couldn't Switch Language",
          });
        }
      } finally {
        setTimeout(() => {
          setTranslationsLoading(false);
        }, 100);
      }
    };

    if (!localeCtx?.selectedLocale?.current.componentTranslations["resetpassword"]) {
      fetchTranslations();
    }
  }, [localeCtx?.selectedLocale]);

  useEffect(() => {

    validateToken();

    if (!user || !token || !clientId) {
      // redirect to unauthorized
      navigate(`/unauthorized`, { replace: true });
    } else {
      getPasswordPolicy();

    }
  }, []);

  const validateToken = async () => {
    let resettokenVal: ValidateResetToken = {
      Email: user,
      ResetToken: token
    }

    let isValid: boolean = await accountService.validateresettoken(resettokenVal, clientId ?? "");

    if (!isValid)
      navigate(`/unauthorized`, { replace: true });
  }

  const getPasswordPolicy = async () => {
    const result: PasswordPolicy = await accountService.passwordPolicy(user)
    setPasswordPolicy(result);
  }

  //#region Locale Translation Methods
  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };
  //#endregion Locale Translation Methods

  const emailValidator = (value: string) => {
    if (!validateEmail(value)) {
      return `${translationsLoading
        ? "Invalid email address."
        : fetchLabelKeyTranslation(
          "InvalidEmailText",
          "Invalid email address."
        )
        }`;
    }

    return "";
  };

  const passwordValidator = (value: string) => {
    if (passwordPolicy != undefined && !validateOnPasswordPolicy(value, passwordPolicy)) {
      return (
        <Error>
          <div>
            {`${translationsLoading
              ? "Password must contain:"
              : fetchLabelKeyTranslation("PasswordContainText", "Password must contain:")
              }`}
            <ul>
              {passwordPolicy?.passwordLength != undefined && passwordPolicy.passwordLength > 0 &&
                <li>
                  {`${translationsLoading
                    ? "Minimum character length: "
                    : fetchLabelKeyTranslation("MinCharLen", "Minimum character length: ")
                    }`}
                  {passwordPolicy.passwordLength}
                </li>
              }

              {passwordPolicy?.isSpecialCharRequired != undefined && passwordPolicy.isSpecialCharRequired == true &&
                <li>
                  {`${translationsLoading
                    ? "Atleast one special character"
                    : fetchLabelKeyTranslation("SpecialChar", "Atleast one special character")
                    }`}
                </li>
              }

              {passwordPolicy?.isNumRequired != undefined && passwordPolicy.isNumRequired == true &&
                <li>
                  {`${translationsLoading
                    ? "Atleast one number"
                    : fetchLabelKeyTranslation("Number", "Atleast one number")
                    }`}

                </li>
              }

              {passwordPolicy?.isUpperCaseCharRequired != undefined && passwordPolicy.isUpperCaseCharRequired == true &&
                <li>{`${translationsLoading
                  ? "At least one uppercase letter and one lowercase letter."
                  : fetchLabelKeyTranslation("UpLowCaseText", "At least one uppercase letter and one lowercase letter."
                  )
                  }`}</li>
              }

            </ul>
          </div>
        </Error>
      );
    }
  };

  const submitHandler = async (values: { [name: string]: any }) => {
    try {
      setLoading(true);
      if (token && clientId) {
        const reqPayload: ResetPasswordRequest = {
          email: values.email,
          newPassword: values.password,
          forgotPasswordToken: token,
        };
        await accountService.resetPassword(reqPayload, clientId);
        await Swal.fire({
          icon: "success",
          title: `${translationsLoading
            ? "Password Reset Successfully"
            : fetchLabelKeyTranslation(
              "SuccessTitle",
              "Password Reset Successfully"
            )
            }`,
          text: `${translationsLoading
            ? "Your password has been successfully reset. Redirecting you back to login page"
            : fetchLabelKeyTranslation(
              "SuccessText",
              "Your password has been successfully reset. Redirecting you back to login page"
            )
            }`,
        });
        navigate(`/login?clientId=${clientId}`, { replace: true });
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        const errMsg = err.response?.data?.message;
        setError(errMsg);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="loginFull float-left w-100 h-100">
      <div className="loginBg h-100 p-l-15 p-r-15">
        <div className="row m-b-20 h-100">
          <div className="col-md-12 h-100">
            {redirecting && (
              <LoadingOverlay
                customStyle={{ position: "fixed", marginTop: "55px" }}
                themeColor={"light"}
                size={"medium"}
                loadingText={`${translationsLoading
                  ? "Redirecting to login page�"
                  : fetchLabelKeyTranslation(
                    "LoadingText",
                    "Redirecting to login page�"
                  )
                  }`}
              />
            )}
            <div className="formGroup h-100">
              <div
                className="formInrG cardEffect"
                style={{ position: "relative" }}
              >
                {brandingCtx?.branding?.logos.find(
                  (l) => l.name === "HeaderPrimaryLogo"
                )?.logoImageUrl && <div className="hdrTrk-logo text-center p-t-5 p-b-15">
                    {/* <span className="fs-22 font-weight">LOGO_PLACEHOLDER</span> */}
                    <img
                      src={
                        brandingCtx?.branding?.logos.find(
                          (l) => l.name === "HeaderPrimaryLogo"
                        )?.logoImageUrl
                      }
                      alt="Logo"
                    />
                  </div>}
                <Form
                  initialValues={{
                    email: user,
                    password: "",
                    confirmPassword: "",
                  }}
                  onSubmit={submitHandler}
                  render={(formRenderProps) => (
                    <FormElement style={{ maxWidth: "100%" }}>
                      <fieldset className={"k-form-fieldset"}>
                        <legend
                          className={"k-form-legend fs-18 font-weight-semi"}
                          style={{ textTransform: "none" }}
                        >
                          {
                            translationsLoading
                              ? "Reset Password"
                              : fetchLabelKeyTranslation(
                                "ResetPasswordText",
                                "Reset Password"
                              )
                          }
                        </legend>
                        {error && <Error>{error}</Error>}
                        <div className="m-b-15">
                          <Field
                            disabled
                            placeholder={`${translationsLoading
                              ? "Email"
                              : fetchLabelKeyTranslation(
                                "EmailFieldPlaceholder",
                                "Email"
                              )
                              }`}
                            name="email"
                            type="email"
                            value={formRenderProps.valueGetter("email")}
                            component={CustomInput}
                            validator={emailValidator}
                          />
                        </div>
                        <div className="m-b-15">
                          <Field
                            placeholder={`${translationsLoading
                              ? "Password"
                              : fetchLabelKeyTranslation(
                                "PasswordFieldPlaceholder",
                                "Password"
                              )
                              }`}
                            name="password"
                            type="password"
                            value={formRenderProps.valueGetter("password")}
                            component={CustomInput}
                          />
                          {formRenderProps.modified &&
                            passwordValidator(
                              formRenderProps.valueGetter("password")
                            )}
                        </div>
                        <div className="m-b-1">
                          <Field
                            placeholder={`${translationsLoading
                              ? "Confirm Password"
                              : fetchLabelKeyTranslation(
                                "ConfirmPasswordPC",
                                "Confirm Password"
                              )
                              }`}
                            name="confirmPassword"
                            type="password"
                            value={formRenderProps.valueGetter(
                              "confirmPassword"
                            )}
                            component={CustomInput}
                            required={true}
                          />
                          {formRenderProps.valueGetter("confirmPassword")
                            .length > 0 &&
                            formRenderProps.valueGetter("password") !==
                            formRenderProps.valueGetter(
                              "confirmPassword"
                            ) && (
                              <Error>
                                {
                                  translationsLoading
                                    ? "Password and Confirm Password must match"
                                    : fetchLabelKeyTranslation(
                                      "ErrorText",
                                      "Password and Confirm Password must match"
                                    )
                                }
                              </Error>
                            )}
                        </div>
                      </fieldset>
                      <div className="k-form-buttons d-flex justify-content-center m-t-15">
                        <Button
                          type={"submit"}
                          style={{ width: "100%", textTransform: "uppercase" }}
                          className={`btn bg-primary text-white fs-16 p-t-7 p-b-7`}
                        >
                          {loading ? (
                            <Loader
                              size="small"
                              type="infinite-spinner"
                              themeColor="light"
                            />
                          ) : (
                            `${translationsLoading
                              ? "Reset"
                              : fetchLabelKeyTranslation(
                                "ResetButton",
                                "Reset"
                              )
                            }`
                          )}
                        </Button>
                      </div>
                    </FormElement>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
